export class WeightFilter {
  constructor(windowSize = 20, stableThreshold = 5) {
    this.windowSize = windowSize; // Number of readings to store
    this.rawReadings = []; // Array to store raw readings
    this.stableThreshold = stableThreshold; // Threshold for stabilized readings
    this.stableReadingsCount = 0; // Counter for stable readings
  }

  addRawReading(rawReading) {
    // console.log(`Received raw reading: ${rawReading}`);
    this.rawReadings.push(rawReading);
    if (this.rawReadings.length > this.windowSize) {
      this.rawReadings.shift(); // Maintain the window size
    }
    // console.log(this.rawReadings);
  }

  calculateMean(array) {
    const sum = array.reduce((acc, value) => acc + value, 0);
    return sum / array.length;
  }

  getFilteredReading(newReading) {
    // Add the new raw reading to the array
    this.addRawReading(newReading);

    if (this.rawReadings.length < this.windowSize) {
      // Not enough data yet, just return the new reading
      return newReading;
    }

    // Remove the highest and lowest values
    const sortedReadings = [...this.rawReadings].sort((a, b) => a - b);
    const trimmedReadings = sortedReadings.slice(2, sortedReadings.length - 2); // Remove first (lowest) and last (highest)

    // Calculate the mean of the trimmed readings
    const filteredMean = this.calculateMean(trimmedReadings);
    console.log(`Filtered mean: ${filteredMean.toFixed(6)}`);
    
    // Count stable readings
    this.stableReadingsCount++;
    if (this.stableReadingsCount >= this.stableThreshold) {
      // console.log("Weight has stabilized around:", filteredMean);
      this.stableReadingsCount = 0; // Reset stability counter
    }

    // Return the filtered mean
    return filteredMean;
  }
}
