import React from 'react';
import { ModalProvider } from './ModalContext';
import { BannerProvider } from './BannerContext';

const UIProvider = ({ children }) => {
  return (
    <ModalProvider>
      <BannerProvider>
        {children}
      </BannerProvider>
    </ModalProvider>
  );
};

export default UIProvider;
