import React, { useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase"; 
import { googleSignIn } from "../utils/FirebaseAuth";
import "../styles/GoogleLoginButton.css";

const GoogleLoginButton = ({ user }) => {
  const loadGoogleIdentityScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById("google-client-script")) {
        resolve(); // Script already loaded
        return;
      }

      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.id = "google-client-script";
      script.async = true;
      script.defer = true;

      script.onload = () => {
        resolve(); // Script loaded successfully
      };

      script.onerror = () => {
        reject(new Error("Failed to load the Google Identity script."));
      };

      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    if (!user) {
      loadGoogleIdentityScript()
        .then(() => {
          if (window.google) {
            window.google.accounts.id.initialize({
              client_id:
                "18891345160-qgji8qcmu85fjm0k01v98lvtcbjtg06j.apps.googleusercontent.com",
              callback: handleCredentialResponse,
            });

            window.google.accounts.id.renderButton(
              document.getElementById("google-sign-in-button"),
              { theme: "outline", size: "large" }
            );
          }
        })
        .catch((error) => {
          console.error("Error loading Google Identity script:", error);
        });
    }
  }, [user]);

  // Handle response from Google sign-in
  const handleCredentialResponse = async (response) => {
    try {
      // Call your Firebase sign-in function and pass the Google ID token
      await googleSignIn(response.credential);
    } catch (error) {
      console.error("Google sign-in error:", error);
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out.");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <div className="google-login-container">
      {user ? (
        <button onClick={handleSignOut}>Sign Out</button>
      ) : (
        <div id="google-sign-in-button"></div> // The official Google button will render here
      )}
    </div>
  );
};

export default GoogleLoginButton;
