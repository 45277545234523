// ModalContext.js
import React, { createContext, useState, useContext } from 'react';
import { Modal, Box, Button, Text } from 'gestalt';
import '../styles/ModalContext.css';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    show: false,
    heading: '',
    message: '',
    onConfirm: null,
    onCancel: null,
    isMessageOnly: false, // Flag to determine if it's just a message or action
  });

  // Show a message-only modal (just "OK")
  const showMessageModal = (heading, message, onConfirm = null) => {
    setModal({
      show: true,
      heading,
      message,
      onConfirm: onConfirm || closeModal, // Default to closing modal on OK
      onCancel: null,
      isMessageOnly: true,
    });
  };

  // Show an action modal ("OK" and "Cancel")
  const showActionModal = (heading, message, onConfirm, onCancel) => {
    setModal({
      show: true,
      heading,
      message,
      onConfirm: onConfirm || closeModal, // Must provide confirm action
      onCancel: onCancel || closeModal, // Default cancel action to close modal
      isMessageOnly: false,
    });
  };

  // Close the modal
  const closeModal = () => setModal({ ...modal, show: false });

  return (
    <ModalContext.Provider value={{ showMessageModal, showActionModal, closeModal }}>
      {children}
      {modal.show && (
        <div className="custom-modal-overlay">
          <Modal
            accessibilityModalLabel="Global Modal"
            heading={modal.heading}
            onDismiss={closeModal}
            footer={
              <Box display="flex" justifyContent="end">
                {modal.isMessageOnly ? (
                  <Button color="blue" text="OK" onClick={modal.onConfirm} />
                ) : (
                  <>
                    <Button text="Cancel" onClick={modal.onCancel} />
                    <Button color="blue" text="OK" onClick={modal.onConfirm} />
                  </>
                )}
              </Box>
            }
          >
            <Box padding={4}>
              <Text>{modal.message}</Text>
            </Box>
          </Modal>
        </div>
      )}
    </ModalContext.Provider>
  );
};