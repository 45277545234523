import {
  getAuth,
  signInWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "../firebase"; // Import the existing Firebase app
import { getFunctions, httpsCallable } from "firebase/functions";

const auth = getAuth(app); // Initialize Firebase Auth
const functions = getFunctions(app); // Initialize Firebase Functions

// Check if a user is authenticated

export const checkAuth = () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.error("No user is currently signed in.");
    return null;
  }
  console.log("User is signed in:", currentUser);
  return currentUser;
};

export const createUser = async (user) => {
  try {
    // Call the Firebase Cloud Function for creating/updating the user
    const createUserFunc = httpsCallable(functions, "createUser");
    const response = await createUserFunc({
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
    });

    console.log("User document created/updated via Cloud Function:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating/updating user in Firestore via Cloud Function:", error);
    throw error;
  }
};


// Function to handle Google Sign-In and create user in Firestore
export const googleSignIn = async (idToken) => {
  try {
    // Create a GoogleAuthProvider credential using the ID token
    const googleCredential = GoogleAuthProvider.credential(idToken);

    // Sign in with Firebase using the Google credential
    const result = await signInWithCredential(auth, googleCredential);
    const user = result.user;

    // After successful sign-in, create or update the user document in Firestore
    await createUser(user); // Call the separate createUser function
    console.log("User signed in and created in Firestore:", user);
    return user;
  } catch (error) {
    console.error("Error during Google Sign-In:", error);
    throw error;
  }
};