import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoadingSpinner from "../components/LoadingSpinner";

import strings from "../utils/strings";

import "./InvitePage.css";
import { navigateToLoginPage } from "../utils/PageNav";

function InvitePage() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(0); // Set initial countdown value (in seconds)
  const [redirectProgress, setRedirectProgress] = useState(0); // Progress bar value
  const [showLoginRedirect, setShowLoginRedirect] = useState(false);

  const functions = getFunctions();
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const countdownIntervalRef = useRef(null);

  // Extract token and groupId from the URL query params
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const groupId = queryParams.get("groupId");

  useEffect(() => {
    const acceptInvite = async (user) => {
      try {
        // Call the acceptInvite Cloud Function
        const acceptInviteFunc = httpsCallable(functions, "acceptInvite");
        const result = await acceptInviteFunc({ token, groupId });

        console.log(result.data);
        if (result.data.success) {
          console.log("result.data.success = true");
          setMessage("You have been successfully added to the group!");
          setLoading(false);
          startCountdown(10);
        } else {
          console.log("result.data.success = false");
          setMessage("Failed to accept the invite. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error accepting the invite: ", error);
        if (error.message.includes("Invalid invitation")) {
          setMessage(
            "Invalid invitation link. Please request a new invitation."
          );
        } else if (error.message.includes("maximum")) {
          setMessage(
            "Failed to accept the invite. The group that invited you has already reached the maximum number of users."
          );
        } else if (error.message.includes("cannot")) {
          setMessage(
            "Failed to accept the invite. The invitation belongs to a different account."
          );
        } else {
          setMessage("Failed to accept the invite. Please try again.");
        }
        setLoading(false);
      }
    };

    // Start the countdown timer
    const startCountdown = (time) => {
      setCountdown(time);
      countdownIntervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(countdownIntervalRef.current); // Stop the timer
            navigate(strings.accountNav); // Redirect after countdown
            setTimeout(() => {
              window.location.reload(); // Refresh the page after navigating
            }, 100);
          }
          return prevCountdown - 1;
        });

        setRedirectProgress((prevProgress) => prevProgress + 100 / time); // Update the progress bar dynamically
      }, 1000);
    };

    // Listen for the current user's authentication status
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If the user is signed in, proceed with accepting the invite
        if (token && groupId) {
          acceptInvite(user);
        } else {
          setMessage("Invalid invitation link.");
          setLoading(false);
        }
      } else {
        // If the user is not signed in, show a message
        setMessage("You need to sign in first before you can accept the invitation.");
        setShowLoginRedirect(true);
        setLoading(false);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
      clearInterval(countdownIntervalRef.current); // Ensure interval is cleared on unmount
    };
  }, [token, groupId, auth, functions, navigate]);

  const handleLoginRedirectClick = () => {
    const queryString = location.search;
    navigateToLoginPage(navigate, queryString);
  };

  return (
    <div className="invite-page">
      {loading ? (
        <>
          <LoadingSpinner
            accessibilityLabel="Loading"
            message={"Processing your invitation..."}
          />
        </>
      ) : (
        <div className="invite-page-section">
          <p>{message}</p>
          {countdown > 0 && (
            <>
              <p>Redirecting in {countdown} seconds...</p>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${redirectProgress}%` }}
                ></div>
              </div>
            </>
          )}
          {showLoginRedirect && (
            <>
              <button onClick={handleLoginRedirectClick}>
                Go to Login Page
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default InvitePage;
