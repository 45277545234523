// SubgroupList.js
import React, { useState, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getFunctions, httpsCallable } from "firebase/functions";

import { UserContext } from "../context/UserContext.js";
import { useBanner } from "../context/BannerContext";
import { useModal } from "../context/ModalContext";
import LoadingContentWrapper from "../components/LoadingContentWrapper";

import "../styles/SubgroupList.css";

function SubgroupList() {
  const { userData, subgroups, updateSubgroups } =
    useContext(UserContext);
  const { showBanner } = useBanner();
  const { showMessageModal, showActionModal, closeModal } = useModal();

  const functions = getFunctions();

  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [subgroupName, setSubgroupName] = useState("");

  const groupId = userData?.group_id;

  const onCreateSubgroupClick = async () => {
    if (subgroupName.trim().length === 0) {
      showBanner(`Subgroup name cannot be blank.`, "error");
      return;
    }

    setLoadingSubgroups(true);
    try {
      const createSubgroupFunc = httpsCallable(functions, "createSubgroup");
      const { success, message } = await createSubgroupFunc({
        groupId,
        subgroupName,
      });

      if (success) {
        showBanner(`Successfully created new subgroup.`, "success");
        window.location.reload();
      }
      //   console.log(message);
    } catch (error) {
      console.error("Error creating subgroup: ", error);
      showBanner(`${error}`, "error");
    } finally {
      setLoadingSubgroups(false);
      setSubgroupName("");
    }
  };

  const handleDeleteSubgroupClick = async (subgroupId) => {
    showActionModal(
      "Delete Subgroup",
      "Are you sure you want to delete this Subgroup?",
      async () => {
        setLoadingSubgroups(true);
        try {
          const deleteSubgroupFunc = httpsCallable(functions, "deleteSubgroup");
          await deleteSubgroupFunc({ groupId, subgroupId });
          updateSubgroups(); // Refresh the scale list after removal
        } catch (error) {
          showMessageModal(
            "Error",
            `Error removing subgroup: ${error.message}`
          );
        } finally {
          setLoadingSubgroups(false);
          closeModal();
        }
      },
      closeModal
    );
  };

  const subgroupColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => params.row.name || "Unknown",
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: (params) => params.row.id || "Unknown",
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 0.9,
      renderCell: (params) => {
        const subgroupRow = params.row;

        // Handle Firestore Timestamp (_seconds and _nanoseconds)
        let createdAtDate;
        if (subgroupRow.createdAt && subgroupRow.createdAt._seconds) {
          // Convert Firestore Timestamp to JavaScript Date
          createdAtDate = new Date(subgroupRow.createdAt._seconds * 1000); // Convert seconds to milliseconds
        }

        // Format the date and time (for example: MM/DD/YYYY HH:MM AM/PM)
        const formattedDate = createdAtDate
          ? createdAtDate.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }) +
            " " +
            createdAtDate.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
          : "N/A"; // Fallback if createdAt is not available

        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.4,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const subgroupRow = params.row;

        return (
          <button
            color="error"
            className="remove-btn"
            title="Remove"
            onClick={() => handleDeleteSubgroupClick(subgroupRow.id)}
          >
            ❌
          </button>
        );
      },
    },
  ];

  if (!subgroups) {
    return (
      <>
        <h3>Subgroups</h3>
        <LoadingContentWrapper
          loading={loadingSubgroups}
          accessibilityLabel="Loading subgroups..."
        />
      </>
    );
  }

  return (
    <div className="subgroup-list-container">
      <h3>Subgroups</h3>
      {subgroups.length === 0 && (
        <>
          <p>No subgroups available.</p>
        </>
      )}
      <div className="subgroup-create-form">
        <input
          type="text"
          placeholder="Enter new subgroup name"
          value={subgroupName}
          onChange={(e) => setSubgroupName(e.target.value)}
        />
        <button onClick={onCreateSubgroupClick}>Create Subgroup</button>
      </div>
      {subgroups.length > 0 && (
        <>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              autoHeight
              rows={subgroups.map((user) => ({ ...user, id: user.id }))} // Ensure DataGrid has an `id` field
              columns={subgroupColumns}
              pageSize={5}
              disableSelectionOnClick
              disableColumnReorder
              className="custom-datagrid"
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // Targets the header text directly to make it bold
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none !important", // Hide the column resize handles
                },
              }}
              localeText={{
                noRowsLabel: "No subgroups available.",
              }}
            />
          </div>
        </>
      )}
      {loadingSubgroups && (
        <LoadingContentWrapper
          loading={loadingSubgroups}
          accessibilityLabel="Loading subgroups..."
        />
      )}
    </div>
  );
}

export default SubgroupList;
