import React, { createContext, useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getGroupIdForUser, getUserRole } from "../utils/FirestoreDB";
import {
  doc,
  onSnapshot,
  getFirestore,
  collection,
  query,
  where,
} from "firebase/firestore";
import { createUser } from "../utils/FirebaseAuth";
import strings from "../utils/strings";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserDataState] = useState(null);

  const [loading, setLoading] = useState(true); // Loading state for async actions
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasBasic, setHasBasic] = useState(false);

  const [isOwner, setIsOwner] = useState(false);
  const [isAdminOrOwner, setIsAdminOrOwnerState] = useState(false);

  const [isScaleMaxed, setIsScaleMaxed] = useState(false);
  const [isUserMaxed, setIsUserMaxed] = useState(false);
  const [isPartMaxed, setIsPartMaxed] = useState(false);

  const [parts, setParts] = useState([]);
  const [scales, setScales] = useState(null);

  const [groupCounts, setGroupCounts] = useState(null);

  const [subgroups, setSubgroups] = useState([]);
  const [userSubgroup, setUserSubgroupState] = useState(null);

  const userDataRef = useRef(userData);
  const userSubgroupRef = useRef(userSubgroup);
  const isAdminOrOwnerRef = useRef(isAdminOrOwner);

  const setUserData = (newData) => {
    setUserDataState(newData);
    userDataRef.current = newData;
  };

  const setUserSubgroup = (newData) => {
    setUserSubgroupState(newData);
    userSubgroupRef.current = newData;
  };

  const setIsAdminOrOwner = (newState) => {
    setIsAdminOrOwnerState(newState);
    isAdminOrOwnerRef.current = newState;
  };

  const fetchPartsBySubgroup = (groupId, subgroupId, isAdminOrOwner) => {
    if (!groupId) {
      console.log("Group ID not available for parts fetch");
      setParts([]); // Clear parts if no group ID
      return;
    }

    const db = getFirestore();
    let partsRef = collection(db, `user_groups/${groupId}/parts`);
    
    // Create the query before setting up the listener
    const partsQuery = subgroupId && !isAdminOrOwner 
      ? query(partsRef, where("subgroupId", "==", String(subgroupId)))
      : partsRef;

    // Set up Firestore snapshot listener with the appropriate query
    const unsubscribe = onSnapshot(
      partsQuery,
      (snapshot) => {
        const partsData = [];
        snapshot.forEach((doc) => {
          partsData.push({ id: doc.id, ...doc.data() });
        });
        setParts(partsData);
        console.log("Parts updated:", partsData.length, "parts");
      },
      (error) => {
        console.error("Error fetching parts:", error);
        setParts([]); // Clear parts on error
      }
    );

    return unsubscribe;
  };

  useEffect(() => {
    let unsubscribe;

    const setupPartsListener = async () => {
      // Only proceed if we have the necessary data
      if (!userData?.group_id) {
        setParts([]); // Clear parts if no group ID
        return;
      }

      unsubscribe = fetchPartsBySubgroup(
        userData.group_id,
        userSubgroup?.subgroupId,
        isAdminOrOwner
      );
    };

    setupPartsListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userData?.group_id, userSubgroup, isAdminOrOwner]);

  // Function to fetch group details in real-time using snapshot listener
  const fetchGroupDetails = (groupId) => {
    if (!groupId) {
      console.error("Group ID is required to fetch group details.");
      return;
    }

    // Reference to the Firestore document
    const groupDocRef = doc(db, "user_groups", groupId);

    // Set up Firestore snapshot listener for group details
    const unsubscribe = onSnapshot(
      groupDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const groupDetails = docSnapshot.data();

          const groupCounts = {
            partCount: groupDetails.partCount,
            partMax: groupDetails.partMax || 0,
            userCount: groupDetails.userCount,
            userMax: groupDetails.userMax || 0,
            scaleCount: groupDetails.scaleCount,
            scaleMax: groupDetails.scaleMax || 0,
          };

          // Determine if the scale limit has been reached
          const hasReachedScaleLimit =
            groupCounts.scaleCount >= groupCounts.scaleMax;
          const hasReachedUserLimit =
            groupCounts.userCount >= groupCounts.userMax;
          const hasReachedPartLimit =
            groupCounts.partCount >= groupCounts.partMax;

          // Update state with group details
          setGroupCounts(groupCounts);
          setIsScaleMaxed(hasReachedScaleLimit);
          setIsUserMaxed(hasReachedUserLimit);
          setIsPartMaxed(hasReachedPartLimit);

          // Update userData with new group details
          setUserData((prevUserData) => ({
            ...prevUserData,
            group_name: groupDetails?.group_name || prevUserData.group_name,
            stripeCustomerId:
              groupDetails?.stripeCustomerId || prevUserData?.stripeCustomerId,
          }));

          console.log("Group details updated:", groupDetails);
          // console.log("userData updated: ", userData);
        } else {
          console.error("No such group document!");
        }
      },
      (error) => {
        console.error("Error fetching group details:", error);
      }
    );

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };

  // Fetch and listen to group details when the groupId changes
  useEffect(() => {
    const groupId = userData?.group_id;

    if (!groupId) {
      console.log("Group ID not available. Canceling fetchGroupDetails()");
      return;
    }

    // Set up the listener
    const unsubscribe = fetchGroupDetails(groupId);

    // Clean up the listener when the component unmounts or groupId changes
    return () => {
      unsubscribe();
    };
  }, [userData?.group_id]);

  const currentSubscription = (subscriptionStatus) => {
    if (
      subscriptionStatus &&
      (subscriptionStatus.status === "active" ||
        subscriptionStatus.status === "trialing")
    ) {
      // console.log(subscriptionStatus);

      if (subscriptionStatus.planName.toLowerCase().includes("basic")) {
        return strings.subTier1;
      } else if (
        subscriptionStatus.planName.toLowerCase().includes("standard")
      ) {
        return strings.subTier2;
      } else if (
        subscriptionStatus.planName.toLowerCase().includes("premium")
      ) {
        return strings.subTier3;
      } else {
        return strings.subTier0;
      }
    } else {
      return strings.subTier0;
    }
  };

  const fetchGroupScales = (groupId) => {
    if (!groupId) {
      console.error("Group ID is required to fetch scales.");
      return;
    }

    // Reference to the Firestore collection
    const scalesRef = collection(db, `user_groups/${groupId}/scales`);

    // Set up Firestore snapshot listener for scales collection
    const unsubscribe = onSnapshot(
      scalesRef,
      (snapshot) => {
        const scalesData = [];
        snapshot.forEach((doc) => {
          scalesData.push({ id: doc.id, ...doc.data() });
        });
        setScales(scalesData); // Update scales in state
        console.log("Scales updated:", scalesData);
      },
      (error) => {
        console.error("Error fetching scales:", error);
      }
    );

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };

  // Fetch scales when the group_id changes
  useEffect(() => {
    const groupId = userData?.group_id;

    if (!groupId) {
      console.log("Group ID not available. Canceling fetchGroupScales()");
      return;
    }

    const unsubscribe = fetchGroupScales(groupId);

    // Clean up the listener when the component unmounts or groupId changes
    return () => {
      unsubscribe();
    };
  }, [userData?.group_id]);

  const fetchSubgroups = async (groupId) => {
    const functions = getFunctions();
    try {
      const fetchSubgroupsFunc = httpsCallable(functions, "fetchSubgroups");

      // Fetch the response from the callable function
      const response = await fetchSubgroupsFunc({ groupId });
      const { success, subgroups } = response.data; // Make sure to access `response.data`

      //   console.log(success);
      //   console.log(subgroups);

      if (success) {
        setSubgroups(subgroups);
        return subgroups;
      }
    } catch (error) {
      console.error("Error fetching subgroups: ", error);
    }
  };

  const updateSubgroups = async (groupId) => {
    console.log(groupId);
    const subgroups = await fetchSubgroups(groupId);
    console.log(subgroups);
    setSubgroups(subgroups);
  };

  const fetchUserSubgroup = async (groupId, userId) => {
    const functions = getFunctions();
    const getUserSubgroup = httpsCallable(functions, "getUserSubgroup");

    try {
      const result = await getUserSubgroup({ groupId, userId });
      if (result.data.success) {
        console.log("Subgroup ID:", result.data.subgroupId);
        console.log("Subgroup Name:", result.data.subgroupName);
        const userSubgroupData = {
          subgroupId: result.data.subgroupId,
          subgroupName: result.data.subgroupName,
        };
        return userSubgroupData;
      } else {
        console.error("Failed to get subgroup information");
      }
    } catch (error) {
      console.error("Error fetching user's subgroup:", error);
    }
  };

  //technically all data, not just user data
  const fetchUserData = async (currentUser) => {
    if (currentUser) {
      try {
        // Promise #1: getUserRole and getGroupIdForUser
        const [role, group_id] = await Promise.all([
          getUserRole(currentUser.uid),
          getGroupIdForUser(currentUser.uid),
        ]);

        // If the role is null, create the user
        if (role === null) {
          await createUser(currentUser);
        }

        let subscriptionResponse = null;
        let userSubgroupData = null;
        let unsubscribeGroupDetails = null;

        if (group_id) {
          unsubscribeGroupDetails = fetchGroupDetails(group_id);

          [subscriptionResponse, , userSubgroupData] = await Promise.all([
            fetchSubscriptionStatus(),
            updateSubgroups(group_id),
            fetchUserSubgroup(group_id, currentUser.uid),
          ]);

          const isOwnerBool = role === "owner";
          const isAdminOrOwnerBool = role === "admin" || role === "owner";

          console.log(userSubgroupData);
          setUserSubgroup(userSubgroupData);
          setIsOwner(isOwnerBool);
          setIsAdminOrOwner(isAdminOrOwnerBool);

        } else {
          console.log("groupless user");
        }

        const subscriptionStatus = subscriptionResponse
          ? subscriptionResponse
          : { status: "no-subscription" };

        const userDataFromFirestore = {
          group_id,
          email: currentUser.email,
          role,
          displayName: currentUser.displayName || null,
          profilePicture: currentUser.photoURL || null,
          subscription_plan: currentSubscription(subscriptionStatus),
          subscriptionStatus,
        };

        console.log(userDataFromFirestore);

        const hasSubscription =
          userDataFromFirestore?.subscription_plan &&
          userDataFromFirestore?.subscription_plan !== "free";

        const hasBasicSub =
          userDataFromFirestore?.subscription_plan &&
          userDataFromFirestore?.subscription_plan == "basic";

        setHasBasic(hasBasicSub);

        console.log(hasSubscription);
        setHasSubscription(hasSubscription);

        setUserData(userDataFromFirestore);

        // Unsubscribe from group details when userData changes or unmounts
        return () => {
          unsubscribeGroupDetails && unsubscribeGroupDetails();
        };
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      setUserData(null);
    }
  };

  // Function to fetch subscription status from Firebase Functions
  const fetchSubscriptionStatus = async () => {
    // console.log("fetchSubscriptionStatus: ", user);
    // if (!user) return;

    try {
      const functions = getFunctions();
      const getSubscriptionStatus = httpsCallable(
        functions,
        "getSubscriptionStatus"
      );
      const { data } = await getSubscriptionStatus();
      console.log("fetchSubscriptionStatus: ", data);
      return data;
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  // Function to refresh the user data manually
  const refreshUserData = async () => {
    if (user) {
      await fetchUserData(user); // Fetch the latest user data
    }
  };

  // Firebase Auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(true); // Start loading while user data is fetched
      if (currentUser) {
        await fetchUserData(currentUser);
      } else {
        setUserData(null); // No user, clear the userData
      }
      setLoading(false); // Data fetching complete
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userData,
        parts,
        groupCounts,
        loading,
        refreshUserData,
        hasBasic,
        hasSubscription,
        currentSubscription,
        scales,
        isOwner,
        isAdminOrOwner,
        fetchGroupScales,
        isScaleMaxed,
        isUserMaxed,
        isPartMaxed,
        subgroups,
        updateSubgroups,
        userSubgroup,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
