import React from "react";
import "../../styles/ConnectSection.css";
import ConnectButton from "./ConnectButton";

function ConnectSection() {
  return (
    <div className="sensor-connect-container homepage-section">
      <div className="connected-device-container">
        <label htmlFor="connected-device-id" className="label-block">
          Serial Port
        </label>
        <ConnectButton/>
      </div>
    </div>
  );
}

export default ConnectSection;
