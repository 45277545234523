const strings = {
  homepageNav: "/",
  homepageAltNav: "/",
  partsNav: "/parts",
  partEditorNav: "/part-editor",
  settingsNav: "/settings",
  accountNav: "/account",
  helpNav: "/help",
  aboutNav: "/about",
  subscriptionNav: "/subscription",
  noAcessNav: "/no-access",
  successNav: "/success",
  cancelNav: "/cancel",
  inviteNav: "/invite",
  subgroupNav: "/subgroup",
  loginNav: "/login",

  userOwnerRole:
    "You have full control over the app, billing, as well as parts, scales, and user management.",
  userAdminRole:
    "You can access parts, scales, and user management. You cannot remove the owner.",
  userEditorRole:
    "You can access scales and manage parts. You cannot manage other users.",
  userDefaultRole:
    "You can access parts and scales, but make changes. You cannot manage other users.",
  subTier0Desc: "You have limited access to features. Upgrade to unlock more.",
  subTier1Desc:
    "You have access to some paid features with support for 1 user, 1 scale, and no parts.",
  subTier2Desc:
    "You have access to all paid features with support for 10 users, 10 scales, and 1,000 parts.",
  subTier3Desc:
    "You have access to all paid features with support for 100 users, 100 scales, and 10,000 parts.",
  subTier4Desc:
    "You have access to all paid features with support for 1000 users.",

  subTier0: "free",
  subTier1: "basic",
  subTier1Month: "basicMonth",
  subTier1Year: "basicYear",
  subTier2: "standard",
  subTier2Month: "standardMonth",
  subTier2Year: "standardYear",
  subTier3: "premium",
  subTier3Month: "premiumMonth",
  subTier3Year: "premiumYear",
};

export default strings;
