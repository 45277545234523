import React, { useState, useContext } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DataGrid } from "@mui/x-data-grid";
import { Spinner } from "gestalt";
import "../styles/GroupScalesList.css";

import LoadingContentWrapper from "../components/LoadingContentWrapper";

import { UserContext } from "../context/UserContext.js";
import { useSerial } from "../context/SerialContext.js";
import { useModal } from "../context/ModalContext";

function GroupScalesList() {
  const { userData, scales, isAdminOrOwner, hasSubscription, isScaleMaxed } =
    useContext(UserContext);

  const { isConnected, onConnectBtn, scaleId } = useSerial();

  const functions = getFunctions();
  const { showMessageModal, showActionModal, closeModal } = useModal();

  const [loadingScales, setLoadingScales] = useState(false); // For initial data loading
  const [addingScale, setAddingScale] = useState(false); // For "Add Scale" button
  const [loadingState, setLoadingState] = useState({}); // For individual scale actionsf

  const groupId = userData?.group_id;

  const handleAddScale = async () => {
    setAddingScale(true);
    try {
      let newScaleId = scaleId;
      if (!(isConnected && newScaleId)) {
        newScaleId = await onConnectBtn();
      }
      console.log(`newScaleId: ${newScaleId}`);
      if (newScaleId === null) return;
      console.log("newScaleId is not null");
      showActionModal(
        "Register Scale",
        `Do you want to register this scale (ID: ${newScaleId})?`,
        async () => {
          closeModal();
          try {
            const addScaleFunc = httpsCallable(functions, "addScaleToGroup");
            const result = addScaleFunc({ groupId, scaleId: newScaleId });
            // console.log(result.data?.message);
            // if (!result.data?.success) {
            //   showMessageModal(
            //     "Error",
            //     `Unable to register scale. ${result.data?.message}`
            //   );
            // }
          } catch (error) {
            showMessageModal("Error", `Error adding scale: ${error.message}`);
          } finally {
            setAddingScale(false);
          }
        },
        closeModal
      );
    } catch (error) {
      console.error("Error adding scale: ", error);
      showMessageModal("Error", `Error adding scale: ${error.message}`);
    } finally {
      setAddingScale(false);
    }
  };

  const handleRemoveScale = async (scaleId) => {
    showActionModal(
      "Remove Scale",
      "Are you sure you want to remove this scale?",
      async () => {
        closeModal();
        setLoadingState((prevState) => ({ ...prevState, [scaleId]: true }));
        try {
          const removeScaleFunc = httpsCallable(
            functions,
            "removeScaleFromGroup"
          );
          await removeScaleFunc({ groupId, scaleId });
          // Filter out removed scale
        } catch (error) {
          showMessageModal("Error", `Error removing scale: ${error.message}`);
        } finally {
          setLoadingState((prevState) => ({ ...prevState, [scaleId]: false }));
        }
      },
      closeModal
    );
  };

  if (loadingScales || !scales) {
    return (
      <LoadingContentWrapper
        loading={loadingScales}
        accessibilityLabel="Loading scales..."
      ></LoadingContentWrapper>
    );
  }

  if (!hasSubscription) {
    return null;
  }

  const columns = [
    { field: "id", headerName: "Scale ID", flex: 1 },
    isAdminOrOwner && {
      field: "remove",
      headerName: "Remove",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const scaleId = params.row.id;
        return loadingState[scaleId] ? (
          <Spinner accessibilityLabel="Loading" show={true} />
        ) : (
          <button
            className="remove-btn"
            title="Remove"
            onClick={() => handleRemoveScale(scaleId)}
          >
            ❌
          </button>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="group-scales-section">
      <h3>Registered Scales</h3>
      {isAdminOrOwner && !isScaleMaxed && (
        <div className="add-scale-section">
          <button onClick={handleAddScale} disabled={addingScale}>
            Add Scale
          </button>
        </div>
      )}
      {isScaleMaxed && (
        <p>You have reached the maximum number of scales for your plan.</p>
      )}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={scales.map((scale) => ({ id: scale.id }))} // Ensure DataGrid has an `id` field
          columns={columns}
          pageSize={5}
          disableSelectionOnClick
          localeText={{
            noRowsLabel:
              "No scales registered. Please register a scale to start using it.",
          }}
          disableColumnReorder
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
              fontSize: "16px",
              borderBottom: "2px solid #ccc",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Targets the header text directly to make it bold
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #ddd",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f9f9f9",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none !important", // Hide the column resize handles
            },
          }}
        />
      </div>
    </div>
  );
}

export default GroupScalesList;
