import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase"; // Your firebase config file
import LoadingContentWrapper from "../LoadingContentWrapper"; // Import the LoadingContentWrapper component

function HelpPartEditor() {
  const [markdownContent, setMarkdownContent] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        // Create a reference to the markdown file in Firebase Storage
        const markdownRef = ref(storage, "help-content/part-editor.md");

        // Get the download URL for the file
        const url = await getDownloadURL(markdownRef);

        // Fetch the content from the URL
        const response = await fetch(url);
        const text = await response.text();

        // Set the markdown content in the state
        setMarkdownContent(text);
        setLoading(false); // Set loading to false once content is fetched
      } catch (error) {
        console.error("Error fetching markdown from Firebase Storage:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchMarkdown();
  }, []);

  return (
    <LoadingContentWrapper loading={loading} accessibilityLabel="Loading part editor help content...">
      <div className="help-content">
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
    </LoadingContentWrapper>
  );
}

export default HelpPartEditor;
