class LocationManager {
    constructor() {
        if (!LocationManager.instance) {
            this.currentLocation = null;
            LocationManager.instance = this;
        }
        return LocationManager.instance;
    }

    setLocation(location) {
        this.currentLocation = location;
    }

    getLocation() {
        return this.currentLocation;
    }

    clearLocation() {
        this.currentLocation = null;
    }
}

// Remove Object.freeze
const instance = new LocationManager();

export default instance;
