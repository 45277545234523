import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext"; // Assuming you have an AuthContext
import LoadingSpinner from "./LoadingSpinner";

const ProtectedRoute = ({ userHasAccess, redirectPath = "/no-access", children }) => {
  const { user, loading } = useContext(AuthContext); // Get the user and loading state from AuthContext

  // If still loading authentication state, show loading spinner or nothing
  if (loading) {
    return <LoadingSpinner show={true} accessibilityLabel="Loading" />;
  }

  // If user is not authenticated, redirect to login page
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // If the user does not have the required access/role, redirect to a "no access" page
  if (!userHasAccess) {
    return <Navigate to={redirectPath} replace />;
  }

  // If everything is valid, render the children components
  return children;
};

export default ProtectedRoute;
