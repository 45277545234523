import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  enableIndexedDbPersistence,
  Timestamp,
  query,
  writeBatch,
} from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import { clearSelectedSku } from "../redux/slices/skuSlice";
import { app } from "../firebase"; // Import the existing Firebase app

// Initialize Firestore and Firebase Auth
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    console.error(
      "Multiple tabs open, offline persistence can only be enabled in one tab at a time."
    );
  } else if (err.code === "unimplemented") {
    console.error("The current browser does not support offline persistence.");
  }
});

// Fetch all parts from Firestore
export async function getAllParts(group_id) {
  try {
    const partsRef = collection(db, `user_groups/${group_id}/parts`);
    const querySnapshot = await getDocs(partsRef);

    const parts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return parts;
  } catch (error) {
    console.error("Error fetching parts: ", error);
    return [];
  }
}

// Helper to fetch group_id for the authenticated user
export async function getGroupIdForUser() {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.error("No user is currently signed in.");
    return null;
  }
  // else{
  //   console.log(currentUser);
  // }

  // Fetch user's group_id from Firestore (assuming it's stored in a 'users' collection)
  const userRef = doc(db, "users", currentUser.uid); // user uid is the document ID
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    return userData.group_id; // Assuming the group_id is stored in userData
  } else {
    console.error("No group_id found for user.");
    return null;
  }
}

// Delete a part from Firestore
export async function deletePart(part_no, group_id) {
  try {
    const partRef = doc(db, `user_groups/${group_id}/parts`, part_no);
    await deleteDoc(partRef);
    clearSelectedSku();
  } catch (error) {
    console.error("Error deleting part", error);
  }
}

// Clear all parts (Firestore doesn’t have a 'clear' operation, so we delete each part)
export async function clearAllParts(group_id) {
  try {
    const querySnapshot = await getDocs(
      collection(db, `user_groups/${group_id}/parts`)
    );
    // const batch = db.batch();
    const batch = writeBatch(db); // Initialize the batch properly

    querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      batch.delete(docRef);
    });

    await batch.commit();
    clearSelectedSku();
  } catch (error) {
    console.error("Error clearing parts: ", error);
  }
}

export const getUserRole = async (uid) => {
  const userRef = doc(db, "users", uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return userData.role || "user"; // Default role as "user" if not defined
  } else {
    console.error("No such document for user:", uid);
    return null; // Default to "user" if document does not exist
  }
};

export async function requestToJoinGroup(groupId) {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("User is not authenticated");
  }

  const userRef = doc(db, "users", currentUser.uid);
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists() && userSnapshot.data().group_id) {
    throw new Error("User is already in a group");
  }

  const requestRef = doc(collection(db, `user_groups/${groupId}/requests`)); // Auto-generate request ID

  // Create join request
  await setDoc(requestRef, {
    group_id: groupId,
    user_id: currentUser.uid,
    displayName: currentUser.displayName,
    email: currentUser.email,
    created_at: Timestamp.now(),
  });
}

export const getGroupRequests = async (groupId) => {
  // Correct the path to reference the subcollection under each group document
  console.log("getGroupRequests groupId: ", groupId);
  const requestsRef = collection(db, `user_groups/${groupId}/requests`);

  const q = query(requestsRef); // Modify the query as needed (e.g., to filter specific requests)
  const querySnapshot = await getDocs(q);

  const requests = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return requests;
};

// Function to reject a request
export const rejectRequest = async (groupId, requestId) => {
  try {
    console.log("Rejecting request: ", requestId);

    // Reference the request document
    const requestRef = doc(db, `user_groups/${groupId}/requests/${requestId}`);

    // 1. Simply delete the request
    await deleteDoc(requestRef);

    console.log("Request rejected and deleted.");
  } catch (error) {
    console.error("Error rejecting request: ", error);
  }
};

// Function to get real-time updates for users in a group
export const getGroupUsers = (groupId, callback) => {
  if (!groupId) {
    console.error("Group ID is required to fetch group users.");
    return;
  }

  const usersRef = collection(db, `user_groups/${groupId}/users`);

  // Set up Firestore snapshot listener
  const unsubscribe = onSnapshot(
    usersRef,
    (snapshot) => {
      const users = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(users); // Pass the users data to the callback
    },
    (error) => {
      console.error("Error fetching group users: ", error);
    }
  );

  // Return the unsubscribe function to allow cleanup
  return unsubscribe;
};

// Function to get the group details from Firestore
export const getGroupDetails = async (group_id) => {
  try {
    const groupDocRef = doc(db, "user_groups", group_id);
    const groupDocSnap = await getDoc(groupDocRef);

    if (groupDocSnap.exists()) {
      return groupDocSnap.data();
    } else {
      console.error("No such group document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching group details:", error);
    throw error;
  }
};
