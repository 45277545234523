import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase.js'; // Firebase Auth instance

import "../styles/SignoutButton.css"

const SignoutButton = () => {
  
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out.');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <div className='signout-button-container'>
      <button onClick={handleSignOut}>Sign Out</button>
    </div>
  );
};

export default SignoutButton;
