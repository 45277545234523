import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";

import "../../styles/ItemSelectSection.css";

import { navigateToPartsPage } from "../../utils/PageNav";
import { setSelectedSku } from "../../redux/slices/skuSlice";

import { UserContext } from "../../context/UserContext";
import { useSerial } from "../../context/SerialContext.js";

function ItemSelectSection({ setIsPrintEnabled }) {
  const { userData, hasSubscription, parts, hasBasic } =
    useContext(UserContext);
  const isBasicUser = userData?.role === "user";

  const {
    setPieceWeight,
    setPartUnit,
    displayPieceWeight,
  } = useSerial();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [desc, setDesc] = useState("n/a");

  // redux
  const selectedSkuData = useSelector((state) => state.sku);

  // Function to populate the SKU dropdown
  async function populateSKU() {
    if (!hasSubscription) return;

    // console.log("populateSKU");
    const skuDropdown = document.getElementById("sku");
    if (!skuDropdown) return; // Ensure skuDropdown exists

    skuDropdown.innerHTML = "";
    // console.log(skuDropdown);

    const defaultOption = document.createElement("option");
    defaultOption.value = "";
    defaultOption.disabled = true;
    defaultOption.selected = true;
    defaultOption.textContent = "Select Item";
    skuDropdown.appendChild(defaultOption);

    parts.forEach((part) => {
      const option = document.createElement("option");
      option.value = part.part_no;
      option.textContent = part.part_no;
      option.setAttribute("part-name", part.name);
      option.setAttribute("piece-weight", part.piece_weight);
      option.setAttribute("units", part.units);
      skuDropdown.appendChild(option);
    });

    // Initialize Select2 after options are populated
    $(skuDropdown).select2({
      placeholder: "Select Item", // Placeholder text
      allowClear: true, // Allow clearing the selection
    });

    // Attach event listener after initializing Select2
    $(skuDropdown).on("change", onSkuSelect);
    setSavedSkuSelection();
  }

  function saveSkuSelection() {
    // console.log("saveSkuSelection");
    try {
      const skuDropdown = document.getElementById("sku");
      const selectedPart = skuDropdown.options[skuDropdown.selectedIndex];

      const sku = selectedPart.value;
      const description = selectedPart.getAttribute("part-name");
      const pieceWeight = selectedPart.getAttribute("piece-weight");
      const units = selectedPart.getAttribute("units");

      setDesc(description);
      setPieceWeight(pieceWeight);
      setPartUnit(units);

      dispatch(
        setSelectedSku({
          selectedSku: sku,
          description,
          pieceWeight,
          units,
        })
      );
    } catch (error) {
      console.log("Error saving SKU selection", error.message);
    }
  }

  function onSkuSelect() {
    saveSkuSelection();
    setIsPrintEnabled(true);
  }

  function setSavedSkuSelection() {
    console.log(selectedSkuData);

    if (selectedSkuData.selectedSku) {
      // Check if the selectedSku exists in the parts array
      const selectedPart = parts.find(
        (part) => part.part_no === selectedSkuData.selectedSku
      );

      const skuDropdown = document.getElementById("sku");
      if (selectedPart) {
        // If the selectedSku exists in parts, update the dropdown and UI

        skuDropdown.value = selectedPart.part_no;
        $(skuDropdown).trigger("change");
      } else {
        console.warn(
          `Selected SKU (${selectedSkuData.selectedSku}) not found in parts.`
        );
        setDesc("n/a");
        setPieceWeight(0);
      }
    }
  }

  useEffect(() => {
    populateSKU();

    // Cleanup on unmount
    return () => {
      const skuDropdown = document.getElementById("sku");
      if (skuDropdown && $(skuDropdown).data("select2")) {
        $(skuDropdown).select2("destroy"); // Clean up Select2 to prevent memory leaks
      }
    };
  }, [parts]);

  const handleEditClick = () => {
    navigateToPartsPage(navigate);
  };

  return (
    <div className="item-select-container homepage-section">
      <div className="item-top-row">
        <div className="item-select">
          <label htmlFor="sku" className="label-block">
            Item ID
          </label>
          <select
            id="sku"
            name="sku"
            defaultValue=""
            className="readout"
            disabled={!hasSubscription || hasBasic}
          >
            <option disabled>Select Item</option>
          </select>
        </div>
        <div>
          <label htmlFor="description" className="label-block">
            Description
          </label>
          <span
            id="description"
            name="description"
            maxLength="32"
            className="item-select-description"
          >
            {desc}
          </span>
        </div>
        <div id="pieceWeightContainer">
          <label htmlFor="pieceWeight" className="label-block">
            Piece Weight
          </label>
          <span id="pieceWeight" className="item-select-piece-weight">
            {displayPieceWeight != 0 ? displayPieceWeight : "n/a"}
          </span>
        </div>
      </div>
      {!isBasicUser && hasSubscription && (
        <button
          id="editButton"
          disabled={hasBasic || !hasSubscription}
          onClick={() => handleEditClick()}
        >
          ADD/EDIT
        </button>
      )}
    </div>
  );
}

export default ItemSelectSection;
