import React from "react";
import { Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";
import "../styles/LoadingSpinner.css";

// Empty page with just the spinner
function LoadingSpinner({accessibilityLabel, message }) {

  return (
    <div className="spinner-container">
      <Spinner show={true} accessibilityLabel={accessibilityLabel} color="darkGray" />
      {message && <p>{message}</p>} {/* Render the message only if it's provided */}
    </div>
  );
}

export default LoadingSpinner;
