import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDSogrlzy0jjCag76d2sMc8bQ1Z68TbWl4",
  authDomain: "ncounter-b2901.firebaseapp.com",
  projectId: "ncounter-b2901",
  storageBucket: "ncounter-b2901.appspot.com",
  messagingSenderId: "18891345160",
  appId: "1:18891345160:web:9daf466d02b8dc4c55f0bd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app);

// Set Firebase Authentication persistence to localStorage
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Auth persistence set to localStorage.");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { auth, app, db, storage };
