import React, { createContext, useContext, useState, useCallback } from "react";
import { BannerSlim, Box, Layer, Icon } from "gestalt";

const BannerContext = createContext();
const bannerDuration = 5000;

export const useBanner = () => useContext(BannerContext);

export const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState({
    show: false,
    text: "",
    type: "success",
    duration: bannerDuration,
  });

  const showBanner = useCallback(
    (text, type = "success", duration = bannerDuration) => {
      setBanner({ show: true, text, type, duration });
      setTimeout(() => {
        setBanner({ ...banner, show: false });
      }, duration);
    },
    [banner]
  );

  return (
    <BannerContext.Provider value={{ showBanner }}>
      {children}
      {banner.show && (
        <Layer>
          <Box
            dangerouslySetInlineStyle={{
              __style: {
                bottom: 50,
                left: "50%",
                transform: "translateX(-50%)",
                padding: "10px 20px",
                width: "fit-content",
              },
            }}
            position="fixed"
          >
            <BannerSlim
              type={banner.type === "success" ? "success" : "error"}
              iconAccessibilityLabel={
                banner.type === "success" ? "Success" : "Error"
              }
              message={banner.text}
              color={banner.type === "info" ? "gray" : undefined} // Define gray color for 'info'
            />
          </Box>
        </Layer>
      )}
    </BannerContext.Provider>
  );
};
