import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { createUser } from "../utils/FirebaseAuth";
import GoogleLoginButton from "../components/GoogleLoginButton"; // Google login button
import { AuthContext } from "../context/AuthContext";
import "./LoginPage.css"; // Update CSS to reflect the new design

import logo from "../assets/loadstar-sensors-logo.png";
import strings from "../utils/strings";

const LoginPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); 

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState(""); // For sign up flow
  const [error, setError] = useState("");
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [message, setMessage] = useState("");
  const auth = getAuth();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const groupId = queryParams.get("groupId");

  // Redirect if user is already logged in
  useEffect(() => {
    if (user) {
      navigate(strings.homepageNav); // Redirect to home page if logged in
    }
  }, [user, navigate]);

  const clearMessages = () => {
    setMessage("");
    setError("");
  };

  const handleSetMessage = (isError, msg) => {
    clearMessages();
    if (msg === "") return;

    if (isError) {
      setError(msg);
    } else {
      setMessage(msg);
    }
  };

  const handleInviteRedirect = () => {
    if (token && groupId) {
      navigate(`/invite?token=${token}&groupId=${groupId}`);
    } else {
      navigate(strings.homepageNav); // If no token or groupId, go to homepage
    }
  };

  // Handle Email/Password login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleInviteRedirect();
    } catch (err) {
      handleSetMessage(true, "Failed to login. Please check your credentials.");
    }
  };

  // Handle Email/Password sign up
  const handleSignup = async (email, password) => {
    const auth = getAuth();
    try {
      // Attempt to create a user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Get the newly created user

      

      // Optionally update the user profile with the name, if the name is provided
      if (name) {
        await updateProfile(user, { displayName: name });
        await user.reload(); // Refresh user data
      }

      // Call createUser to save user information in Firestore
      await createUser(user);
      handleInviteRedirect();
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        if (signInMethods.includes("google.com")) {
          setError(
            "This email is already associated with a Google account. Please log in using Google."
          );
        } else {
          setError("This email is already in use with another provider.");
        }
      } else {
        setError(error.message);
      }
    }
  };

  // Handle password reset request
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      handleSetMessage(
        false,
        "Password reset email sent! Please check your inbox."
      );
      setIsForgotPassword(false); // Reset the form after success
    } catch (err) {
      handleSetMessage(
        true,
        "Failed to send password reset email. Please try again."
      );
    }
  };

  return (
    <div id="login-page">
      <div className="login-page-image"></div>
      <div className="login-form-container">
        <div className="login-form-header">
          <img src={logo} alt="Logo" className="login-logo" />
          <h2>nCounter Counting Solution</h2>
        </div>

        {/* Conditionally render login, signup, or forgot password form */}
        <div className="login-form">
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}

          {/* For signup, we also ask for a name */}
          {isSignup && (
            <div className="form-group">
              <i className="fas fa-user icon"></i>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          )}

          <div className="form-group">
            <i className="fas fa-envelope icon"></i>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {!isForgotPassword && (
            <div className="form-group">
              <i className="fas fa-lock icon"></i>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          )}

          {!isSignup && !isForgotPassword && (
            <a
              className="forgot-password url-link-no-underline"
              onClick={() => setIsForgotPassword(true)} // Show forgot password UI
            >
              Forgot Password?
            </a>
          )}

          <button
            type="button" // Button type should be button, not submit
            className="login-button"
            onClick={
              isSignup
                ? () => handleSignup(email, password)
                : isForgotPassword
                ? handlePasswordReset
                : handleLogin
            }
          >
            {isSignup
              ? "Sign Up"
              : isForgotPassword
              ? "Reset Password"
              : "Login"}
          </button>

          <div className="signup-alt-container">
            <p>
              {isSignup ? (
                <>
                  Already have an account?{" "}
                  <span
                    className="login-links url-link-no-underline"
                    onClick={() => setIsSignup(false)}
                  >
                    Login
                  </span>
                </>
              ) : isForgotPassword ? (
                <>
                  Remembered your password?{" "}
                  <span
                    className="login-links url-link-no-underline"
                    onClick={() => setIsForgotPassword(false)}
                  >
                    Login
                  </span>
                </>
              ) : (
                <>
                  Don't have an account?{" "}
                  <span
                    className="login-links url-link-no-underline"
                    onClick={() => setIsSignup(true)}
                  >
                    Create Account
                  </span>{" "}
                  or{" "}
                  <a
                    href="https://www.loadstarsensors.com/"
                    className="login-links url-link-no-underline"
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Prevents security vulnerabilities
                  >
                    Learn More
                  </a>
                </>
              )}
            </p>
          </div>
        </div>

        {!isSignup && !isForgotPassword && (
          <>
            {/* Google Login */}
            <div className="separator">OR</div>
            <GoogleLoginButton />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
