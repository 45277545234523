import React from "react";

import LoadingContentWrapper from "./LoadingContentWrapper";

import "../styles/GroupJoinRequests.css";

function GroupJoinRequests({
  requests,
  loading,
  handleApproveRequest,
  handleRejectRequest,
}) {
  if (loading) {
    return (
      <>
        <h3>Requests to Join Group</h3>
        {/** Not sure if this is loading properly */}
        <LoadingContentWrapper
          loading={loading}
          accessibilityLabel="Loading requests..."
        ></LoadingContentWrapper>
      </>
    );
  }

  return (
    <div className="request-processing-section">
      <h3>Requests to Join Group</h3>
      {requests.length === 0 ? (
        <p>No requests available</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>User Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request) => (
              <tr key={request.id}>
                <td>{request.displayName}</td>
                <td>{request.email}</td>
                <td>
                  <button
                    className="approve-btn"
                    title="Approve"
                    onClick={() =>
                      handleApproveRequest(request.group_id, request.id)
                    }
                  >
                    ✔️
                  </button>
                  <button
                    className="reject-btn"
                    title="Reject"
                    onClick={() =>
                      handleRejectRequest(request.group_id, request.id)
                    }
                  >
                    ❌
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default GroupJoinRequests;
