import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import * as units from "../utils/units.js";
import AveragePoints from "../utils/averagePoints.js";
import strings from "../utils/strings.js";
import LocationManager from "../utils/LocationManager";
import { convertWeight } from "../utils/units";
import { WeightFilter } from "../utils/WeightFilter.js";
import { UserContext } from "./UserContext";
import { useBanner } from "./BannerContext";

// Create a context
const SerialContext = createContext();

// Custom hook to use the SerialContext
export const useSerial = () => useContext(SerialContext);

export const SerialProvider = ({ children }) => {
  const { scales } = useContext(UserContext);
  const { showBanner } = useBanner();

  const [isConnected, setIsConnected] = useState(false);
  const [isScaleRegistered, setIsScaleRegisteredState] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [isTareDisabled, setIsTareDisabled] = useState(true);

  const [weight, setWeight] = useState(null);
  const [avgWeight, setAvgWeight] = useState(0);
  const [displayWeight, setDisplayWeightState] = useState(weight);
  const displayWeightRef = useRef(displayWeight);
  const setDisplayWeight = (newState) => {
    setDisplayWeightState(newState);
    displayWeightRef.current = newState;
  };

  const [enableOverride, setEnableOverrideState] = useState(false);
  const enableOverrideRef = useRef(enableOverride); 
  const setEnableOverride = (newState) => {
    setEnableOverrideState(newState);
    enableOverrideRef.current = newState;
  };

  const [overrideWeight, setOverrideWeightState] = useState(1);
  const [pieceWeight, setPieceWeightState] = useState(0);
  const [displayPieceWeight, setDisplayPieceWeightState] =
    useState(pieceWeight);

  const [scaleId, setDeviceId] = useState(null);
  const [scaleCapacity, setDeviceCapacity] = useState(null);
  const [scaleUnit, setScaleUnitState] = useState(null);
  const [selectedUnit, setSelectedUnitState] = useState("lb");
  const [partUnit, setPartUnitState] = useState("lb");

  const [avgPointSetting, setAvgPointSetting] = useState(1);

  const [itemCount, setItemCount] = useState(null);

  const [terminalOutput, setTerminalOutput] = useState("");

  const scaleUnitRef = useRef(scaleUnit);
  const selectedUnitRef = useRef(selectedUnit);
  const partUnitRef = useRef(partUnit);
  const isReadingSettingsRef = useRef(false);
  const isScaleRegisteredRef = useRef(isScaleRegistered);

  const setScaleUnit = (newState) => {
    setScaleUnitState(newState);
    scaleUnitRef.current = newState;
  };

  const setSelectedUnit = (newState) => {
    setSelectedUnitState(newState);
    selectedUnitRef.current = newState;
  };

  const setPartUnit = (newState) => {
    setPartUnitState(newState);
    partUnitRef.current = newState;
  };

  const pieceWeightRef = useRef(pieceWeight);
  const overrideWeightRef = useRef(overrideWeight);

  const setIsScaleRegistered = (newState) => {
    setIsScaleRegisteredState(newState);
    isScaleRegisteredRef.current = newState;
  };

  const setPieceWeight = (weight) => {
    // console.log("setPieceWeight: ", weight);
    setPieceWeightState(weight);
    pieceWeightRef.current = weight;
  };

  const displayPieceWeightRef = useRef(pieceWeight);

  const setDisplayPieceWeight = (weight) => {
    // console.log("setPieceWeight: ", weight);
    setDisplayPieceWeightState(weight);
    displayPieceWeightRef.current = weight;
  };

  useEffect(() => {
    overrideWeightRef.current = overrideWeight;
  }, [overrideWeight]);

  const setOverrideWeight = (weight) => {
    try {
      const parsedWeight = parseFloat(weight);
      if (!isNaN(parsedWeight)) {
        setOverrideWeightState(weight);
        overrideWeightRef.current = weight;
      }
    } catch (error) {
      console.error("Error setting override weight:", error);
    }
  };

  const location = useLocation();
  const previousPage = useRef(location.pathname);

  const portRef = useRef(null);
  const readerRef = useRef(null);
  const inputStreamRef = useRef(null);
  const outputStreamRef = useRef(null);
  const inputDoneRef = useRef(null);
  const outputDoneRef = useRef(null);
  const intervalIdRef = useRef(null);

  // Ref to persist these objects
  const avgPointsRef = useRef(new AveragePoints()); // Initialize with default value of 1
  const weightFilterRef = useRef(new WeightFilter(20, 3));
  const textDecoderRef = useRef(new TextDecoderStream());
  const textEncoderRef = useRef(new TextEncoderStream());

  const avgPoints = avgPointsRef.current;
  const weightFilter = weightFilterRef.current;
  const textDecoder = textDecoderRef.current;
  const textEncoder = textEncoderRef.current;

  const connectToScale = async () => {
    try {
      portRef.current = await navigator.serial.requestPort();
      await portRef.current.open({ baudRate: 9600 });

      inputDoneRef.current = portRef.current.readable.pipeTo(
        textDecoder.writable
      );
      outputDoneRef.current = textEncoder.readable.pipeTo(
        portRef.current.writable
      );
      inputStreamRef.current = textDecoder.readable;

      outputStreamRef.current = textEncoder.writable.getWriter();
      readerRef.current = inputStreamRef.current.getReader();
      console.log("Connected to Scale");
      setIsConnected(true);

      return true;
    } catch (error) {
      console.error("Error: ", error);
      return false;
    }
  };

  //   //doesn't work. Haven't tested recently.
  //   const disconnectScale = async () => {
  //     try {
  //       stopReadHome();
  //       setIsTareDisabled(true);
  //       if (readerRef.current) {
  //         await readerRef.current.cancel();
  //         await readerRef.current.releaseLock();
  //         reader = null;
  //       }
  //       if (outputStream) {
  //         await outputStream.releaseLock();
  //         outputStream = null;
  //       }
  //       if (port) {
  //         await port.close();
  //         port = null;
  //       }
  //       console.log("Disconnected successfully");
  //     } catch (error) {
  //       console.error("Error during disconnect:", error);
  //     }
  //   };

  const onConnectBtn = async () => {
    if (isConnected) {
      window.location.reload();
    } else {
      return startConnectProcess();
    }
  };

  const startConnectProcess = async () => {
    if (!isConnected) {
      try {
        // Serial connection to scale
        await connectToScale();

        // Check scale registration
        const scaleIdToCheck = await checkId();
        let scaleIsRegistered = scales.some(
          (scale) => scale.id === scaleIdToCheck
        );
        console.log(`scaleIsRegistered: ${scaleIdToCheck}`);
        setIsScaleRegistered(scaleIsRegistered);

        let currentPage = LocationManager.getLocation().pathname;
        if (currentPage === strings.accountNav) {
          console.log("is account page");
          return scaleIdToCheck;
        }
        console.log("not account page");
        onHandleRead();
      } catch (error) {
        console.error("Error: ", error);
      }
    }
  };

  const onHandleRead = async () => {
    if (!isReading) {
      // Action based on page user is on
      let currentPage = LocationManager.getLocation().pathname;
      console.log(currentPage);
      if (currentPage === strings.homepageNav) {
        await checkUnits();
        await checkCapacity();

        startReadHome();
      } else if (currentPage === strings.partEditorNav) {
        startReadPartEditor();
      } else if (currentPage === strings.settingsNav) {
        startReadSettings();
      }
    }
  };

  const startReadHome = async () => {
    console.log("startReadHome");
    if (isScaleRegisteredRef.current) {
      // Clear any existing interval first
      stopReadHome();

      setIsReading(true);
      const interval = 100;
      await readWeightOnce();
      intervalIdRef.current = setInterval(readWeightOnce, interval);
    } else {
      showBanner("Scale must be registered before use.", "error");
    }
  };

  const stopReadHome = () => {
    console.log("stopReadHome");
    setIsReading(false);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    // Reset the weight filter
    weightFilterRef.current = new WeightFilter(20, 3);
  };

  useEffect(() => {
    return () => {
      console.log("Component cleanup");
      stopReadHome();
      stopReadSettings();
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      if (readerRef.current) {
        readerRef.current.cancel();
      }
      if (outputStreamRef.current) {
        outputStreamRef.current.releaseLock();
      }
      setIsReading(false);
    };
  }, []);

  const toggleReadHome = () => {
    if (isReading) {
      stopReadHome();
    } else {
      startReadHome();
    }
  };

  const startReadPartEditor = async () => {
    if (isScaleRegisteredRef.current) {
      startReadHome();
    } else {
      showBanner("Scale must be registered before use.", "error");
    }
  };

  const stopReadPartEditor = async () => {
    stopReadHome();
  };

  const startReadSettings = async () => {
    setIsReading(true);
    isReadingSettingsRef.current = true;
    try {
      if (isReadingSettingsRef.current) console.log("startReadingSettings");
      while (isReadingSettingsRef.current) {
        const { value, done } = await readerRef.current.read();
        if (done || !isReadingSettingsRef.current) {
          break;
        }
        setTerminalOutput((prevOutput) => prevOutput + value);
      }
    } catch (error) {
      console.error("Error during settingsReadLoop:", error);
    }
  };

  const stopReadSettings = () => {
    setIsReading(false);
    isReadingSettingsRef.current = false; // Set the flag to false to stop the loop
  };

  const readWeightOnce = async () => {
    try {
      await sendCommand("w");
      const { value } = await readerRef.current.read();
      if (!value.trim()) return;

      const totalWeight = parseFloat(value.trim());
      if (isNaN(totalWeight)) return;

      const filteredWeight = weightFilter.getFilteredReading(totalWeight);
      setWeight(filteredWeight);

      if (LocationManager.getLocation().pathname === strings.homepageNav) {
        updateMainPageWeight(filteredWeight);
      }
    } catch (error) {
      console.error("Error during readAndUpdate:", error);
    }
  };

  const tare = async () => {
    await sendCommand("ct0");
  };

  const sendCommand = async (command) => {
    if (!outputStreamRef.current) {
      console.error("outputStream is null. Cannot send command.");
      return;
    }
    try {
      await outputStreamRef.current.write(command + "\r");
      if (command !== "w") console.log(`Command "${command}" sent`);
    } catch (error) {
      console.error("Error sending command: ", error);
    }
  };

  const checkId = async () => {
    await sendCommand("id");
    const response = await readFullResponse();
    setDeviceId(response.toLowerCase());
    return response.toLowerCase();
  };

  const checkCapacity = async () => {
    await sendCommand("slc");
    const response = await readFullResponse();
    const parsedCapacity = parseFloat(response);
    setDeviceCapacity(isNaN(parsedCapacity) ? "n/a" : parsedCapacity);
  };

  const checkUnits = async () => {
    await sendCommand("units");
    const response = await readFullResponse();
    setScaleUnit(response.toLowerCase());
    units.setDefaultUnits(response);
  };

  const checkSettings = async () => {
    await sendCommand("settings");
  };

  const readFullResponse = async () => {
    if (!readerRef.current) {
      console.error("reader is null. Cannot read response.");
      return;
    }

    let fullResponse = "";
    while (true) {
      const { value, done } = await readerRef.current.read();
      if (done) {
        console.warn("Stream closed unexpectedly.");
        break;
      }
      fullResponse += value;
      if (fullResponse.includes("\r") || fullResponse.includes("\n")) {
        break;
      }
    }
    return fullResponse.trim();
  };

  const updateMainPageWeight = (weight) => {
    weight = Number(
      units
        .convertWeight(weight, scaleUnitRef.current, selectedUnitRef.current)
        .toFixed(4)
    );
    avgPoints.addReading(weight);
    const averageWeight = avgPoints.getAverage().toFixed(4);

    // console.log(`${weight} -> ${averageWeight}`);

    setDisplayWeight(averageWeight);
    setAvgWeight(averageWeight);

    let pieceWeightVal = 0;

    /**
     * check toggle status
     * update the count based on if toggle is on */
    if (enableOverrideRef.current) {
      pieceWeightVal = overrideWeightRef.current;
      // console.log("override: ", pieceWeightVal);
    } else {
      pieceWeightVal = displayPieceWeightRef.current;
      // console.log("no override: ", pieceWeightVal);
    }

    // console.log(`${averageWeight} / ${pieceWeightVal}`);
    // console.log(!isNaN(pieceWeightVal) && pieceWeightVal > 0);

    if (!isNaN(pieceWeightVal) && pieceWeightVal > 0 && averageWeight > 0) {
      let itemCount = Math.floor(averageWeight / pieceWeightVal);
      setItemCount(itemCount);
    } else {
      setItemCount(0);
    }
  };

  // Update enableOverride state and its ref in a useEffect
  useEffect(() => {
    enableOverrideRef.current = enableOverride;
    updateMainPageWeight(weight);
  }, [enableOverride, overrideWeight, weight]);

  // // Trigger updateMainPageWeight when overrideWeight changes as well
  // useEffect(() => {
  //   updateMainPageWeight(weight);
  // }, [overrideWeight, weight]);

  useEffect(() => {
    setDisplayPieceWeight(
      Number(convertWeight(pieceWeight, partUnit, selectedUnit)).toFixed(4)
    );
  }, [pieceWeight, partUnit, selectedUnit]);

  useEffect(() => {
    if (previousPage.current !== location.pathname) {
      // Page has changed
      console.log("page changed");
      handlePageChange(location.pathname, previousPage.current);
      previousPage.current = location.pathname;
    } else {
      console.log("same page");
    }
  }, [location]);

  const handlePageChange = (currentPage, previousPage) => {
    if (currentPage !== previousPage && previousPage !== "") {
      console.log("Cleaning up page change...");
      // Ensure all reading processes are stopped
      stopReadHome();
      stopReadSettings();
      
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      setIsReading(false);

      if (previousPage === strings.homepageNav) {
        setEnableOverride(false);
        setOverrideWeight(1);
      }

      if (!isConnected) {
        return;
      }

      if (currentPage === strings.settingsNav) {
        startReadSettings();
      }
    }
  };

  useEffect(() => {
    if (isConnected) {
      setTerminalOutput((prevOutput) => prevOutput + "Device Connected \n");
    }
  }, [isConnected]);

  // Automatically check and update isScaleRegistered based on changes to scaleId or scales
  useEffect(() => {
    if (isConnected && scaleId && scales) {
      const isRegistered = scales.some((scale) => scale.id === scaleId);
      setIsScaleRegistered(isRegistered);
    }
  }, [isConnected, scaleId, scales]); // Dependencies are scaleId and scales

  /**
   * Expose functions and state via context
   */
  return (
    <SerialContext.Provider
      value={{
        isConnected,
        setIsConnected,
        isScaleRegistered,
        setIsScaleRegistered,
        isReading,
        onHandleRead,
        stopReadHome,
        toggleReadHome,
        weight,
        avgWeight,
        displayWeight,
        displayWeightRef,
        scaleId,
        scaleCapacity,
        scaleUnit,
        connectToScale,
        onConnectBtn,
        tare,
        isTareDisabled,
        setIsTareDisabled,
        avgPoints,
        avgPointSetting,
        setAvgPointSetting,
        pieceWeight,
        setPieceWeight,
        displayPieceWeightRef,
        displayPieceWeight,
        setDisplayPieceWeight,
        overrideWeight,
        setOverrideWeight,
        checkSettings,
        startReadPartEditor,
        stopReadPartEditor,
        selectedUnit,
        setSelectedUnit,
        partUnit,
        setPartUnit,
        itemCount,
        terminalOutput,
        enableOverride,
        setEnableOverride,
      }}
    >
      {children}
    </SerialContext.Provider>
  );
};
