import strings from "./strings";

export function navigateToHomePage(navigate) {
  navigate(strings.homepageNav);
}

export function navigateToPartsPage(navigate) {
  navigate(strings.partsNav);
}

export function navigateToPartEditorPage(navigate, part = null, mode="add") {
  navigate(strings.partEditorNav, { state: { part, mode } });
}

export function navigateToSettingsPage(navigate) {
  navigate(strings.settingsNav);
}

export function navigateToAccountPage(navigate) {
  navigate(strings.accountNav);
}

export function navigateToHelpPage(navigate, location) {
  //   console.log(location);
  navigate(strings.helpNav, { state: { from: location.pathname } });
}

export function navigateToSubscriptionPage(navigate) {
  navigate(strings.subscriptionNav);
}

export function navigateBack(navigate, location) {
  navigate(location.state?.from || strings.homepageNav);
}

export function navigateToSubgroupPage(navigate){
  navigate(strings.subgroupNav);
}

export function navigateToLoginPage(navigate, queryString = ""){
  navigate(`${strings.loginNav}/${queryString}`);
}