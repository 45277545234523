import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/HelpButton.css";

import { navigateToHelpPage } from "../../utils/PageNav";

function HelpButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const onHelpClick = () => {
    navigateToHelpPage(navigate, location);
  };

  return (
    <button
      id="helpButton"
      className="help-button"
      title="Need help?"
      onClick={onHelpClick}
    >
      <i className="fas fa-question-circle"></i>
    </button>
  );
}

export default HelpButton;
