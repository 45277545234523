import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { navigateToPartsPage } from "../utils/PageNav.js";
import { getFunctions, httpsCallable } from "firebase/functions";

import "./PartEditorPage.css";
import { UserContext } from "../context/UserContext.js";
import { useSerial } from "../context/SerialContext.js";
import { useBanner } from "../context/BannerContext";
import ConnectButton from "../components/Home/ConnectButton.js";

function PartEditorPage() {
  const { userData, subgroups, isPartMaxed } = useContext(UserContext);
  const { isConnected, weight, tare, startReadPartEditor, stopReadPartEditor } =
    useSerial();
  const { showBanner } = useBanner();
  const functions = getFunctions();

  const navigate = useNavigate();
  const location = useLocation();
  const { part: selectedPart, mode } = location.state || {};

  const [isSaving, setIsSaving] = useState(false);
  // State for form fields
  const [partNo, setPartNo] = useState("");
  const [partName, setPartName] = useState("");
  const [weightPerPiece, setWeightPerPiece] = useState("");
  const [units, setUnits] = useState("lb");
  const [partSubgroupId, setPartSubgroupId] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  const weightPerPieceMax = 100000;

  useEffect(() => {
    if (selectedPart) {
      console.log(selectedPart);
      handleTitleChange();
      setPartNo(
        mode === "copy" ? `${selectedPart.part_no} copy` : selectedPart.part_no
      );
      setPartName(selectedPart.name);
      setWeightPerPiece(selectedPart.piece_weight);
      setUnits(selectedPart.units);
      setPartSubgroupId(selectedPart.subgroupId || "");
      console.log(selectedPart.subgroupName);
    }
  }, [selectedPart, mode]);

  useEffect(() => {
      setDisableSave(mode !== "edit" && isPartMaxed);
  }, [mode, isPartMaxed]);

  const handleTitleChange = () => {
    const title = selectedPart ? "Edit Part" : "Add Part";
    document.getElementById("part-editor-title").textContent = title;
  };

  const handlePartNoChange = (e) => {
    // console.log("handlePartNoChange: ", e.target.value.length);
    const value = e.target.value;
    if (value.length > 64) {
      showBanner("Part number must be 64 characters or less.", "error");
    } else {
      setPartNo(value);
    }
  };

  const handlePartNameChange = (e) => {
    const value = e.target.value;
    if (value.length > 64) {
      showBanner("Part name must be 64 characters or less.", "error");
    } else {
      setPartName(value);
    }
  };

  const handleWeightPerPieceChange = (e) => {
    const value = e.target.value;
    if (value > weightPerPieceMax) {
      showBanner(`Weight value cannot exceed ${weightPerPieceMax}.`, "error");
    } else {
      setWeightPerPiece(value);
    }
  };

  const savePart = async () => {
    if (!partNo || !partName || !weightPerPiece) {
      showBanner("All fields are required", "error");
      return;
    }

    if (isNaN(weightPerPiece) || parseFloat(weightPerPiece) <= 0) {
      showBanner("Weight Per Piece must be a positive number", "error");
      return;
    }

    if (partNo.length > 64 || partName.length > 64) {
      showBanner(
        "Part number and part name must be less than 64 characters",
        "error"
      );
      return;
    }

    if (weightPerPiece > weightPerPieceMax) {
      showBanner(
        `Weight per piece must be less than ${weightPerPieceMax}`,
        "error"
      );
      return;
    }

    const part = {
      part_no: partNo,
      name: partName,
      piece_weight: weightPerPiece,
      units: units,
    };

    if (partSubgroupId) {
      const selectedSubgroup = subgroups.find(
        (subgroup) => subgroup.id === partSubgroupId
      );
      if (selectedSubgroup) {
        part.subgroupId = selectedSubgroup.id;
        part.subgroupName = selectedSubgroup.name;
      }
    }

    try {
      setIsSaving(true);
      const addOrUpdatePart = httpsCallable(functions, "addOrUpdatePart");

      const response = await addOrUpdatePart({
        part,
        group_id: userData.group_id,
        isUpdate: !!selectedPart && mode !== "copy", // Don't update if it's a copy
      });

      if (response.data.success) {
        showBanner(response.data.message, "success");
        navigateToPartsPage(navigate); // Navigate back to parts page after success
      } else {
        showBanner(response.data.message, "error");
      }
    } catch (error) {
      showBanner(error.message, "error");
    } finally {
      setIsSaving(false);
    }
  };

  const onCalculateBtn = () => {
    const itemCountInput = document.getElementById("calculateItemCountInput");
    const itemCount = parseInt(itemCountInput.value);

    if (isNaN(itemCount) || itemCount <= 0) {
      showBanner("Invalid item count", "error");
      return;
    }

    const totalWeight = parseFloat(
      document.getElementById("calculateReadoutItem").textContent
    );
    const weightPerPiece = totalWeight / itemCount;

    if (isNaN(weightPerPiece) || weightPerPiece <= 0) {
      showBanner("Invalid weight per piece", "error");
      return;
    }

    setWeightPerPiece(weightPerPiece.toFixed(4));
    document.getElementById("toggleInstructionsBtn").click();
    showBanner("Calculated weight per piece", "success");
    stopReadPartEditor();
  };

  const handleCalculateWeightToggleClick = () => {
    const instructions = document.getElementById(
      "calculate-instruction-container"
    );
    instructions.style.display =
      instructions.style.display === "none" ? "block" : "none";

    if (instructions.style.display === "block" && isConnected) {
      startReadPartEditor();
    } else {
      stopReadPartEditor();
    }
  };

  return (
    <div id="part-editor-page">
      <div id="newPartFormSection">
        <h3 id="part-editor-title">Add Part</h3>
        <div className="container">
          <div className="part-editor-form-container">
            <label htmlFor="partNo">Part Number</label>
            <input
              type="text"
              id="partNo"
              name="partNo"
              value={partNo}
              onChange={handlePartNoChange}
              disabled={!!selectedPart && mode !== "copy"} // Disable if editing, enable if copy
            />

            <label htmlFor="partName">Part Name</label>
            <input
              type="text"
              id="partName"
              name="partName"
              value={partName}
              onChange={handlePartNameChange}
            />

            <label htmlFor="weightPerPiece">Weight Per Piece</label>
            <input
              type="text"
              id="weightPerPiece"
              name="weightPerPiece"
              value={weightPerPiece}
              onChange={handleWeightPerPieceChange}
            />

            <label htmlFor="units">Units</label>
            <select
              id="units"
              name="units"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
            >
              <option value="lb">lb</option>
              <option value="kg">kg</option>
            </select>

            <label htmlFor="subgroup">Subgroup</label>
            <select
              id="subgroup"
              name="subgroup"
              value={partSubgroupId} // Set the selected value
              onChange={(e) => setPartSubgroupId(e.target.value)}
            >
              <option value="" disabled>
                Select Subgroup
              </option>
              {subgroups.map((subgroup) => (
                <option key={subgroup.id} value={subgroup.id}>
                  {subgroup.name}
                </option>
              ))}
            </select>
          </div>

          <div className="calculate-section">
            <div className="toggle-container">
              <button
                id="toggleInstructionsBtn"
                onClick={handleCalculateWeightToggleClick}
              >
                Calculate weight per piece <i className="fas fa-caret-down"></i>
              </button>
            </div>
            <div
              id="calculate-instruction-container"
              style={{ display: "none" }}
            >
              <div>
                <ol id="calculateInstructions">
                  <li>
                    <span>Connect to a scale.</span>
                  </li>
                  <ConnectButton />
                  <li>
                    <span>Empty the scale and click Tare.</span>
                  </li>
                  <div className="controls">
                    <div className="bottom-controls">
                      <button id="tareButton" onClick={tare}>
                        Tare
                      </button>
                    </div>
                  </div>
                  <li>Place a known number of pieces on the scale.</li>
                  <div>
                    <div className="calculate-readout-container">
                      <label
                        htmlFor="calculateReadoutItem"
                        className="label-block"
                      >
                        Item Weight
                      </label>
                      <div id="calculateReadoutItem">
                        {weight ? weight.toFixed(4) : "n/a"}
                      </div>
                    </div>
                  </div>

                  <li>
                    <span>Enter the number of pieces here:</span>
                    <input
                      type="text"
                      id="calculateItemCountInput"
                      name="calculateItemCountInput"
                    />
                  </li>
                  <li>
                    <button id="calculateBtn" onClick={onCalculateBtn}>
                      Calculate Weight per Piece
                    </button>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div
            id="part-editor-bottom-container"
            className="part-editor-bottom-container"
          >
            <div className="bottom-button-group">
              <button
                id="okBtn"
                onClick={savePart}
                disabled={isSaving || disableSave} // Disable the button when saving
              >
                {isSaving ? "SAVING..." : "SAVE"}
              </button>
              <button
                id="cancelBtn"
                onClick={() => navigateToPartsPage(navigate)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartEditorPage;
