import React from "react";

import { useSerial } from "../../context/SerialContext";

function ConnectButton() {
  const {
    isConnected,
    onConnectBtn,
  } = useSerial();

  return (
    <button id="connectButton" onClick={onConnectBtn}>
      {isConnected ? "DISCONNECT" : "CONNECT"}
    </button>
  );
}

export default ConnectButton;
