import React, { useState } from "react";
import "./HomePage.css";

import ItemSelect from "../components/Home/ItemSelectSection";
import ConnectSection from "../components/Home/ConnectSection.js";
import Readout from "../components/Home/ReadoutSection";
import ControlsSection from "../components/Home/ControlsSection";

function HomePage() {
  const [isPrintEnabled, setIsPrintEnabled] = useState(false);

  return (
    <div id="home-page">
      <div className="home-container">
        <ConnectSection />
        <ItemSelect setIsPrintEnabled={setIsPrintEnabled} />
        <Readout />
        <ControlsSection isPrintEnabled={isPrintEnabled} />
      </div>
    </div>
  );
}

export default HomePage;
