import React from "react";
import { Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";
import "../styles/LoadingSpinnerOverlay.css";

function LoadingSpinnerOverlay({ accessibilityLabel, message }) {
  return (
    <div className="spinner-overlay"> {/* The overlay covers the entire page */}
      <div className="spinner-container">
        <Spinner show={true} accessibilityLabel={accessibilityLabel} color="white" /> {/* Centered spinner */}
        {message && <p className="spinner-message">{message}</p>} {/* Optional message */}
      </div>
    </div>
  );
}

export default LoadingSpinnerOverlay;
