import React from "react";
import { Box, Spinner } from "gestalt"; // Import Spinner from Gestalt
import "../styles/LoadingContentWrapper.css";

const LoadingContentWrapper = ({
  loading,
  children,
  accessibilityLabel = "Loading content...",
}) => {
  return (
    <div className="loading-content-wrapper-container">
      <Box
        padding={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {loading ? (
          <div className="spinner-container">
            <Spinner
              show={true}
              accessibilityLabel={accessibilityLabel}
              color="darkGray"
            />
          </div>
        ) : (
          children
        )}
      </Box>
    </div>
  );
};

export default LoadingContentWrapper;
