// src/pages/NoAccessPage.js
import React from "react";

import "./NoAccessPage.css"; // Create this CSS file for NoAccessPage specific styles

const NoAccessPage = () => {
  return (
    <div id="no-access-page">
      <div className="no-access-page-header-container">
        <h1 id="no-access-page-title">Access Denied</h1>
      </div>
      <div className="no-access-page-container">
        <div className="no-access-page-section">
          <p>You do not have permission to view this page.</p>
        </div>
      </div>
    </div>
  );
};

export default NoAccessPage;
