import React, { useState, useContext } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserContext } from "../context/UserContext";
import { useBanner } from "../context/BannerContext";
import "../styles/GroupUserInvites.css";

function GroupUserInvites() {
  const functions = getFunctions();
  const { userData, isAdminOrOwner } = useContext(UserContext); // Access user data from context
  const { showBanner } = useBanner();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const groupId = userData.group_id;
  const groupName = userData.group_name;

  const handleSendInvite = async () => {
    if (!email) {
      showBanner('Please enter an email.', 'error');
      return;
    }
    setLoading(true);

    try {
      // Call the sendGroupInvite Cloud Function
      const sendInviteFunc = httpsCallable(functions, "sendGroupInvite");
      const result = await sendInviteFunc({ email, groupId, groupName });

      if (result.data.success) {
        showBanner(`Invitation sent to ${email}`, 'success');
        setEmail(""); // Clear the input field
      }
    } catch (error) {
      console.error("Error sending invite:", error);
      showBanner('Failed to send invitation.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // If the user is not an admin or owner, hide the component
  if (!isAdminOrOwner) {
    return null;
  }

  return (
    <div className="group-user-invites">
      <h3>Invite Users to Group</h3>
      <div className="invite-form">
        <input
          type="email"
          placeholder="Enter user email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleSendInvite} disabled={loading}>
          {loading ? "Sending..." : "Send Invite"}
        </button>
      </div>
    </div>
  );
}

export default GroupUserInvites;
