import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner, Divider } from "gestalt";
import "gestalt/dist/gestalt.css";
import { UserContext } from "../context/UserContext.js";
import { getFunctions, httpsCallable } from "firebase/functions";

import strings from "../utils/strings.js";
import { capitalizeFirstLetter } from "../utils/stringUtils.js";
import { navigateToAccountPage } from "../utils/PageNav.js";
import LoadingSpinner from "../components/LoadingSpinner.js";

import "./SubscriptionPage.css"; // Styles for SubscriptionPage

function SubscriptionPage() {
  const { userData, loading, refreshUserData } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  const groupId = userData?.group_id;
  const ownerEmail = userData?.email;

  const [loadingRequests, setLoadingRequests] = useState(false); // State for overall loading
  const [loadingButton, setLoadingButton] = useState(false); // State for button-specific loading
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // State for subscription status

  // Fetch subscription status from Cloud Function
  const fetchSubscriptionStatus = async () => {
    setLoadingRequests(true); // Start loading
    try {
      const functions = getFunctions();
      const getSubscriptionStatus = httpsCallable(
        functions,
        "getSubscriptionStatus"
      );
      const { data } = await getSubscriptionStatus();

      console.log("subscription page: getSubscriptionStatus: ", data);

      setSubscriptionStatus(data); // Set the subscription status (active, canceled, etc.)
    } catch (error) {
      console.error("Error fetching subscription status:", error);
      setSubscriptionStatus({ status: "no-subscription" }); // Handle case where no subscription is found
    } finally {
      setLoadingRequests(false); // Stop loading
    }
  };

  useEffect(() => {
    if (!loading && !userData) {
      refreshUserData(); // Fetch fresh user data if missing
    }

    // Fetch subscription status after userData is fetched
    if (userData && !subscriptionStatus) {
      fetchSubscriptionStatus();
    }
  }, [loading, userData, subscriptionStatus, refreshUserData, location]);

  const subscriptionDefinition = (sub) => {
    switch (sub) {
      case strings.subTier1:
        return strings.subTier1Desc;
      case strings.subTier2:
        return strings.subTier2Desc;
      case strings.subTier3:
        return strings.subTier3Desc;
      default:
        return strings.subTier0Desc;
    }
  };

  // Handle subscription cancellation or redirection to Stripe Customer Portal
  const handleManageSubscription = async () => {
    setLoadingButton(true); // Start spinner when button is clicked
    try {
      const functions = getFunctions();
      const createBillingPortalSession = httpsCallable(
        functions,
        "createBillingPortalSession"
      );
      const { data } = await createBillingPortalSession();
      window.location.href = data.url; // Redirect to Stripe Customer Portal
    } catch (error) {
      console.error("Error handling subscription action:", error);
    } finally {
      setLoadingButton(false); // Stop spinner after process completes
    }
  };

  const onBackClick = () => {
    navigateToAccountPage(navigate);
  };

  const hasActiveSubscription = () => {
    if (
      subscriptionStatus &&
      (subscriptionStatus.status === "active" ||
        subscriptionStatus.status === "trialing")
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (loading || loadingRequests) {
    return (
      <div>
        <LoadingSpinner show={true} accessibilityLabel="Loading page" />
      </div>
    );
  }

  return (
    <div id="subscription-page">
      <div className="subscription-page-header-container">
        <h3 id="subscription-page-title">Subscription Management</h3>
      </div>
      <div className="subscription-container">
        <div className="subscription-page-section">
          <div className="current-subscription-section">
            <h3>Current Subscription Plan</h3>
            {userData && userData.subscription_plan && (
              <>
                <h4>{capitalizeFirstLetter(userData.subscription_plan)}</h4>
                <p>{subscriptionDefinition(userData.subscription_plan)}</p>
              </>
            )}
          </div>
          <div className="available-subscriptions-section">
            <Divider />
            {!hasActiveSubscription() ? (
              <>
                <h3>Available Subscriptions</h3>
                <p>Choose a subscription plan to unlock our paid features:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Parts Manager: </strong>Create and Edit parts to
                      count your items.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Cloud Storage: </strong>All parts are stored on
                      the cloud and shared across your team.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Import/Export Parts: </strong>Bulk import parts
                      via CSV file and export them for backup.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Teams Management: </strong>Add team members and
                      add them to sub-groups.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Scale Registration: </strong>Register your scales
                      to use nCounter counting.
                    </p>
                  </li>
                </ul>
                <Divider />
                <div className="stripe-pricing-table-container">
                  <stripe-pricing-table
                    pricing-table-id="prctbl_1Q7Lo92Kzr2AfPNVDd0dwCHc"
                    publishable-key="pk_test_51Py16u2Kzr2AfPNVtXzLP3RfdxlhNaMzQU5zdqQwbyyTcwc2NPsNnDCwlyYgNM733DNpOoHD5zwI18OWZem6qqtJ00BKUydzFm"
                    client-reference-id={groupId}
                    customer-email={ownerEmail}
                  ></stripe-pricing-table>
                </div>
              </>
            ) : (
              <div className="manage-subscription-section">
                <h3>Manage Your Subscription</h3>
                <p>
                  Follow the link below to change your subscription or billing
                  details.
                </p>
                <p>
                  You will be redirected to the Stripe Billing Management
                  Portal.
                </p>
                <button
                  onClick={handleManageSubscription}
                  disabled={loadingButton}
                >
                  Go to Stripe
                </button>
              </div>
            )}
          </div>
          <div className="success-button-group">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
      {/* Show spinner while loading */}
      {loadingButton && (
        <div className="spinner-overlay">
          <div className="spinner-container">
            <Spinner show accessibilityLabel="Loading" color="default" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionPage;
