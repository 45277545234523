import React from "react";
import { useNavigate } from "react-router-dom";
import "./SuccessPage.css"; // Create this CSS file for HelpPage specific styles
import { navigateToHomePage } from "../utils/PageNav";

function SuccessPage() {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigateToHomePage(navigate);
  };

  return (
    <div id="success-page">
      <div id="successSection">
        <div className="container">
          <div>
            <h4>Transaction Success!</h4>

            <p>
              Your transaction has been successfully completed. Thank you for
              your purchase.
            </p>
          </div>
          <div className="success-button-group">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
