import { createSlice } from '@reduxjs/toolkit';

const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState: {
    deviceId: '',
    deviceCapacity: 0,
    deviceUnits: 'lb', // You can add any other fields you need
  },
  reducers: {
    setDeviceInfo(state, action) {
      state.deviceId = action.payload.deviceId;
      state.deviceCapacity = action.payload.deviceCapacity;
      state.deviceUnits = action.payload.deviceUnits;
    },
    clearDeviceInfo(state) {
      state.deviceId = '';
      state.deviceCapacity = 0;
      state.deviceUnits = 'lb';
    },
  },
});

export const { setDeviceInfo, clearDeviceInfo } = deviceInfoSlice.actions;
export default deviceInfoSlice.reducer;
