import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import "./PartsListPage.css";
import { deletePart } from "../utils/FirestoreDB";
import { importParts, exportParts } from "../utils/ImportExportFile";
import {
  navigateToHomePage,
  navigateToPartEditorPage,
} from "../utils/PageNav.js";
import LoadingSpinnerOverlay from "../components/LoadingSpinnerOverlay.js";
import { capitalizeFirstLetter } from "../utils/stringUtils.js";

import { UserContext } from "../context/UserContext";
import { useBanner } from "../context/BannerContext.js";
import { useModal } from "../context/ModalContext";

function PartsListPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const {
    userData,
    hasSubscription,
    subgroups,
    isAdminOrOwner,
    userSubgroup,
    parts,
    isPartMaxed,
  } = useContext(UserContext);
  const { showBanner } = useBanner();
  const { showActionModal, closeModal } = useModal();

  const isBasicUser = userData?.role === "user";

  const handleImportClick = () => {
    if (parts?.length) {
      showActionModal(
        "Import Parts",
        "Warning: Importing new parts will clear all existing parts. Please ensure you have backed up your current data using the export function. Do you want to proceed?",
        () => {
          const fileInput = fileInputRef.current;
          fileInput.click();
          closeModal();
        },
        closeModal
      );
    } else {
      const fileInput = fileInputRef.current;
      fileInput.click();
    }
  };

  async function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true); // Show spinner
      try {
        let importPartsFinished = await importParts(
          file,
          userData.group_id,
          showBanner,
          subgroups
        );
        if (importPartsFinished) {
          console.log("Parts imported successfully...updating parts");
        } else {
          console.log("Parts import failed");
        }
      } catch (error) {
        console.error("Error during import:", error);
      } finally {
        // fetchPartsBySubgroup();
        setIsLoading(false); // Hide spinner after import finishes
      }
    } else {
      console.error("No file selected");
    }
  }

  const handleExportClick = () => {
    exportParts(userData.group_id, showBanner);
  };

  const handleNewClick = () => {
    navigateToPartEditorPage(navigate);
  };

  const handleBackClick = () => {
    navigateToHomePage(navigate);
  };

  const handleEditClick = (part) => {
    navigateToPartEditorPage(navigate, part, "edit");
  };

  const handleCopyClick = (part) => {
    navigateToPartEditorPage(navigate, part, "copy");
  };

  const handleDeleteClick = async (partId) => {
    if (!partId) {
      console.error("Invalid part ID:", partId);
      return;
    }

    try {
      await deletePart(partId, userData.group_id);
      // fetchPartsBySubgroup();
    } catch (error) {
      console.error("Failed to delete part:", error);
    }
  };

  const columns = [
    { field: "part_no", headerName: "Part No.", flex: 1 },
    { field: "name", headerName: "Part Name", flex: 1.5 },
    {
      field: "piece_weight",
      headerName: "Wt. Per Piece",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "units",
      headerName: "Units",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    ...(isAdminOrOwner
      ? [
          {
            field: "subgroupName",
            headerName: "Subgroup",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => params.row.subgroupName || "N/A",
          },
        ]
      : []), // Add subgroup column for owners and admins only
    ...(!isBasicUser
      ? [
          {
            field: "actions",
            headerName: "Actions",
            flex: 0.8,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
              <div className="action-buttons">
                <button
                  className="edit-btn"
                  onClick={() => handleEditClick(params.row)}
                  title="Edit Part"
                >
                  <i className="fas fa-pencil-alt"></i>
                </button>
                <button
                  className="copy-btn"
                  onClick={() => handleCopyClick(params.row)}
                  title="Copy Part"
                  disabled={isPartMaxed}
                >
                  <i className="fas fa-copy"></i>
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteClick(params.row.id)}
                  title="Delete Part"
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            ),
            hide: isBasicUser || !hasSubscription, // Hide for basic users or if no premium subscription
          },
        ]
      : []), // Add subgroup column for owners and admins only
  ];

  return (
    <div id="parts-list-page">
      <div id="partsListSection">
        <h3>{isBasicUser ? "Parts List" : "Parts Manager"}</h3>
        <div className="container">
          {isAdminOrOwner ? (
            <p>
              {capitalizeFirstLetter(userData?.role)} role has access to all
              parts.
            </p>
          ) : (
            <p>
              <strong>Subgroup: </strong>
              {userSubgroup?.subgroupName
                ? userSubgroup?.subgroupName
                : "You have not been assigned a Subgroup."}
            </p>
          )}
          {isPartMaxed && (
            <p style={{ color: "red" }}>
              You've reached the maximum number of parts for your subscription.
            </p>
          )}
          <div style={{ minHeight: 400, width: "100%" }}>
            <DataGrid
              autoHeight
              rows={parts.map((part) => ({ ...part, id: part.id }))} // Ensure DataGrid has an `id` field
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="custom-datagrid"
              disableColumnReorder
              localeText={{
                noRowsLabel:
                  "No parts available. Please add parts to the list.", // Custom message
              }}
              sx={{
                width: "750px",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold", // This makes the headerName bold
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // This targets the header text directly
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#f9f9f9",
                },
                "& .action-buttons button": {
                  marginRight: "10px",
                  color: "#007bff",
                },
                "& .edit-btn": {
                  color: "white",
                },
                "& .delete-btn": {
                  color: "white",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none !important", // Hide the column resize handles
                },
              }}
            />
          </div>

          <div className="bottom-container">
            <div className="bottom-button-group">
              <input
                type="file"
                ref={fileInputRef}
                id="fileInput"
                style={{ display: "none" }}
                accept=".csv"
                onChange={handleFileChange}
              />
              {!isBasicUser && hasSubscription && (
                <button onClick={handleImportClick}>IMPORT</button>
              )}
              <button onClick={handleExportClick}>EXPORT</button>
              {!isBasicUser && hasSubscription && (
                <button onClick={handleNewClick} disabled={isPartMaxed}>
                  ADD PART
                </button>
              )}
              <button onClick={handleBackClick}>BACK</button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinnerOverlay />}
    </div>
  );
}

export default PartsListPage;
