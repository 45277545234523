class AveragePoints {
  constructor(x = 1) {
    this.x = x; // Number of points to average
    this.readings = [];
    this.index = 0;
  }

  addReading(newReading) {
    if (this.readings.length < this.x) {
      // If array isn't full yet, just add the new reading
      this.readings.push(newReading);
    } else {
      // If the array is full, overwrite the oldest reading
      this.readings[this.index] = newReading;
    }

    // Move the index forward, wrap around if necessary
    this.index = (this.index + 1) % this.x;
  }

  getAverage() {
    // console.log("getAverage arr.length: ", this.readings.length);
    if (this.readings.length === 1) {
      return this.readings[0];
    } else {
      const sum = this.readings.reduce((acc, val) => acc + val, 0);
      return sum / this.readings.length;
    }
  }

  updateX(newX) {
    // console.log(`Updating x to ${newX}`);
    this.x = newX;
    this.readings = []; // Clear readings when x is updated
    this.index = 0;
  }
}

// Export the class for use in other files
export default AveragePoints;
